<template>
  <div class="bg-green-50 py-1 page-section">

    <div class="relative" v-if="currentComponent !== ''">
        <div class="absolute top-0 right-0">
          <a href="#" class="underline" @click="showComponentDefault">Back</a>
        </div>
    </div>

    <div class="container" v-if="currentComponent === ''">
      <div class="text-center wow fadeInUp">
        <div class="text-2xl mb-3">
          <br />
          <h2 class="title-section text-2xl">
            Exhibitors
          </h2>
        </div>
        <div class="divider mx-auto"></div>
      </div>

      <div class="flex flex-col lg:flex-row items-center justify-evenly space-x-3 h-full text-left py-1">

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/be.png" class="flag border border-gray-400" alt="Belgium">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Belgium</h2>
            </div>
            <p class="text-left leading-relaxed">
              <h2 class="font-bold">Frabelle Marketplace & Belgian Waffle</h2>
              <h2 class="text-xs lg:text-xs ">Try something sweet, something salty, and something bitter - authentic waffles, ice cream, fries, and beer - all from Belgium.</h2>
            </p>
          </div>

          <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/cz.png" class="flag border border-gray-400" alt="Czech Republic">
              <h2 class="title-section ml-4 text-base sm:text-base font-bold">The Czech Republic</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Czech Beer & Pretzel</h2>
              <h2 class="text-xs lg:text-xs ">Taste authentic pretzels and pilsners thanks to the Embassy of the Czech Republic.</h2>
            </p>
        </div>

        
        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <a href="#">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/dk.png" class="flag border border-gray-400" alt="Denmark">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Denmark</h2>
            </div>
            <p class="text-left leading-relaxed">
              <h2 class="font-bold">Arla Products</h2>
              <h2 class="text-xs lg:text-xs ">Discover Arla and get the best of dairy - from milk to delicious butter - at Denmark's booth.</h2>
            </p>
          </a>
          </div>

          <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/de.png" class="flag border border-gray-400" alt="Germany">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Germany</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold text-xs lg:text-xs">Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) & Brie and Rye Bakery</h2>
              <h2 class="text-xs lg:text-xs">Learn more about GIZ and their projects at the German Booth while enjoying their coffee and pastries!Or maybe try to learn a few phrases, like Guten Tag, in German at the language corner with Goethe Institut!</h2>
            </p>
        </div>
        <!-- <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/es.png" class="flag border border-gray-400" alt="Spain">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Spain</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold text-xs lg:text-xs">Embassy of Spain</h2>
              <h2 class="text-xs lg:text-xs">Vamos a comer! Try the Spanish gastronomy, and you might find some paella. Hablamos, let’s learn and participate in Instituto Cervantes Manila’s booth and learn a few Spanish phrases.</h2>
            </p>
        </div> -->
        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/fr.png" class="flag border border-gray-400" alt="France">
              <h2 class="title-section ml-4 font-bold">France</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Paris Crepe, Fluffyyummy & Paris Delice</h2>
              <h2 class="text-xs lg:text-xs">Get food coma with authentic Crêpes, Flammekueche and Croissants at the French booth! Learn to say yummy in french and more at Alliance Francaise de Manille at the language corner!</h2>
            </p>
        </div>
      </div>
      

      <div class="flex flex-col lg:flex-row items-center justify-evenly space-x-3 h-full text-left py-1">

       

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/it.png" class="flag border border-gray-400" alt="Italy">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Italy</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Divino Ristorante by Chef Paolo Durante</h2>
              <h2 class="text-xs lg:text-xs">Molto bene! Watch as authentic Italian pasta is cooked in front of your eyes at Italy's booth! Check out the Philippine Italian Association booth at the Language corner to learn Italian phrases like "molto bene”.</h2>
            </p>
        </div>

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/hu.png" class="flag border border-gray-400" alt="Hungary">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Hungary</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">ACAY Missions & Embassy of Hungary</h2>
              <h2 class="text-xs lg:text-xs">Check out Teg Ball - a sport that combines table tennis and football, curious? Try it out at the Hungary's booth! While you wait your turn grab some authentic Hungarian sausages and more!</h2>
            </p>
        </div>

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/nl.png" class="flag border border-gray-400" alt="Netherlands">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">The Netherlands</h2>
          </div>
          <p class="text-left leading-relaxed">
            <h2 class="font-bold text-xs lg:text-xs">Dutch Delight — AFSI Inc. (Amsterdam Food Services International Inc.)</h2>
              <h2 class="text-xs lg:text-xs">Enjoy a chewy, gooey, sweet stroopwafel and poffertjes fresh from the booth of the Netherlands.</h2>
            </p>
        </div>
          
        <!-- <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <a href="#">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <br />
              <img src="../assets/EUMemberStates/at.png" class="flag border border-gray-400" alt="Austria">
              <h4 class="title-section ml-4 text-2xl sm:text-xl font-bold">Austria</h4>
            </div>
            <p class="text-left leading-relaxed">
              <h2 class="font-bold">Red Bull</h2>
              <h2 class="text-xs lg:text-xs">Get energized and feel that Formula One buzz with Red Bull!</h2>
            </p>
          </a>
          </div> -->
          <div  @click="showComponentAPoland" class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/pl.png" class="flag border border-gray-400" alt="Poland">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Poland</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Oleo Fats Company, Palawan Smokers & Reachmore</h2>
              <h2 class="text-xs lg:text-xs">Have some pierogi, a polish dumpling at the Polish booth and a gulp of apple juice to complete the experience.</h2>
            </p>
        </div>

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/ro.png" class="flag border border-gray-400" alt="Romania">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Romania</h2>
            </div>
            <p class="text-left leading-relaxed">
              <h2 class="font-bold">Brumms Quality Wines, Inc. & Embassy of Romania</h2>
              <h2 class="text-xs lg:text-xs">Taste Romanian festive pastries and pair them with fine wine from Brumms Quality Wines, Inc. Also, from a counter rich in music, dances, tales, and more, catch Ms. Daniela Coman interpret songs from traditional Romanian folklore.</h2>
            </p>
          </div>

          
      </div>

      <div class="flex flex-col lg:flex-row items-center justify-evenly space-x-3 h-full text-left py-1">
        

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/fi.png" class="flag border border-gray-400" alt="Finland">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Finland</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Embassy of Finland</h2>
              <h2 class="text-xs lg:text-xs">Experience a fun simple throwing game, Mölkky you can play anywhere with the Embassy of Finland.</h2>
            </p>
        </div>



        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-1">
              <img src="../assets/EUMemberStates/ukraine.png" class="flag border border-gray-400" alt="Ukraine">
              <h2 class="title-section ml-4 text-2xl sm:text-xl font-bold">Ukraine</h2>
          </div>
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">Consulate of Ukraine</h2>
              <h2 class="text-xs lg:text-xs">Support the Consulate by checking out their merch from shirts, tote bags, and mugs designed by a Ukrainian artist, authentic homemade Ukrainian smoked sausages from Kovbaska Boracay, and authentic Ukrainian cuisine from original recipes from Odessa Mama Cafe and Boodmo Brewery based in El Nido, Palawan.</h2>
            </p>
        </div>

        

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">&nbsp;&nbsp;Language Corner</h2>  
              </p>

          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/EUMemberStates/GoetheInstitut.png" class="flag-icon border border-gray-400" alt="GeotheInstitut">
              <h2 class="title-section text-xs ml-4 font-bold">Geothe Institut</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/EUMemberStates/InstitutoCervantesManila.jpg" class="flag-icon border border-gray-400" alt="ICM">
              <h2 class="title-section text-xs ml-4 font-bold">Instituto Cervantes Manila</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
            <img src="../assets/EUMemberStates/AllianceFrancaisedeManille.png" class="flag-icon border border-gray-400" alt="AFM">
            <h2 class="title-section text-xs ml-4 font-bold">Alliance Francaise de Manille</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-4 pl-4">
              <img src="../assets/EUMemberStates/PhilippineItalianAssociation.jpg" class="flag-icon border border-gray-400" alt="PIA">
              <h2 class="title-section text-xs ml-4 font-bold">Philippine Italian Association</h2>
            </div>
          
          
        </div>

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">&nbsp;&nbsp;Sustainability Partners</h2>  
              </p>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/sustainability_partners/Eco-Nest-Logo_360x.jpg" class="flag-icon border border-gray-400" alt="ECO Nest">
              <h2 class="title-section text-xs ml-4 font-bold">ECO Nest</h2>
          </div>
            <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
            <img src="../assets/sustainability_partners/sachi.png" class="flag-icon border border-gray-400" alt="Sachi Group">
            <h2 class="title-section text-xs ml-4 font-bold">Sachi Group</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/sustainability_partners/Greenspace_Logo.png" class="flag-icon border border-gray-400" alt="Greenspace">
              <h2 class="title-section text-xs ml-4 font-bold">Greenspace</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/sustainability_partners/Philippine_Alliance_Logo.jpg" class="flag-icon border border-gray-400" alt="Philippine Alliance">
              <h2 class="title-section text-xs ml-4 font-bold">Philippine Alliance</h2>
          </div>
          <div class="flex justify-center items-center md:justify-start md:items-start text-2xl font-semibold text-green-800 mt-2 pl-4">
              <img src="../assets/sustainability_partners/SOS.png" class="flag-icon border border-gray-400" alt="SOS">
              <h2 class="title-section text-xs ml-4 font-bold">SOS Philippines</h2>
            </div>
        </div>

        <div class="w-full md:w-screen bg-white rounded-xl lg:w-1/5 drop-shadow-md mt-3 col-lg-4 ml-2 py-1 px-5 h-60 hover:-mt-3 cursor-default hover:shadow-lg hover:shadow-green-100 overflow-hidden">
          <p class="text-left leading-relaxed">
              <h2 class="font-bold">&nbsp;&nbsp;Venue Partner </h2>  
              </p>

            <div class="flex justify-center items-center  ">
              <div><img src="../assets/venue_partner/Capitol_Commons_Logo_Full_Color_-_Core_copy.png" class="ortigas-flag" alt="Capitol Commons"></div>
              <div><img src="../assets/venue_partner/ortigasland.png" class="ortigas" alt="Ortigas Land" ></div>
            </div>

            
            <div class="flex justify-center items-center  ">
              <img src="../assets/venue_partner/OrtigasCommunityCard.png" class="ortigas-community" alt="Ortigas Land" >
            </div>
            <div class="flex justify-center items-center  ">
            <a href="https://ortigasmalls.com" class="py-2 px-4 bg-blue-500 hover:bg-blue-1 00 text-white font-semibold rounded-lg shadow-md">
            Sign Up Now!
            </a>
            </div>
            
        </div>

        <br />
        <br />
        <br />
        <br />
      </div>
    </div>

    <div class="container" v-if="currentComponent === 'Poland'">
      <Poland />
    </div>

  </div>
</template>

<script>
import Poland from "../components/countries/Poland.vue";
export default {
  data() {
    return {
      scrollPosition: 0,
      currentComponent: ''
    };
  },
  components: {
      Poland,
  },
  methods: {
    handleScroll(e) {
      this.scrollPosition = e.target.scrollTop;

      if (this.scrollPosition > 100) {
        console.log("UP");
      } else {
        console.log("DOWN");
      }
    },
    showComponentAPoland(){
      this.currentComponent = 'Poland';
    },
    showComponentDefault(){
      this.currentComponent = '';
    }
  },
};
</script>

<style>

@media (min-width: 768px) {
  div.rounded-xl {
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
}

h2 {
  color: #04277a;
}

h2.ex-p {
  color: #04277a;
}
br {
   display:block !important;
}

.flag-icon {
  width: 50px; 
  height: 30px; 
}
img.ortigas-flag {
  width: 80px; 
  height: 50px; 
}

img.ortigas{
  width: 160px; 
  height: 50px; 
}
img.ortigas-community{
  width: 160px; 
  height: 90px; 
}
</style>
