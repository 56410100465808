<template>
  <Navbar />
  <!-- <div class="container mt-5">
    <div class="page-banner1">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-6"> -->
          <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ul>
          </nav>

          <div class="py-1 page-section">
    <div class="container">
      <button class="block md:hidden" @click="showSidebar = !showSidebar">
              <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0zm0 6h20v2H0zm0 6h20v2H0z"/></svg>
            </button>
  <div class="af flex h-screen"  v-if="isAdmin == false">
    <div class="bg-gray-200 w-64 h-60 afo flex-shrink-0" :class="{ 'hidden': !showSidebar }">
      <ul class="mt-8">
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer">
          Dashboard
        </li>
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer" @click="stamps">
          Stamps
        </li>
        <li class="px-6 py-2 text-red-700 hover:bg-gray-400 cursor-pointer" @click="signout">
          Logout
        </li>
      </ul>
    </div>
    <div class="flex-1 p-10">
        <div class="af flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold">Welcome, Guest {{ nameDisplay }}!</h1>
        </div>
            <p>This is euro village account dashboard!</p>
            <ul class="mt-4 flex flex-wrap">
              <li class="w-full md:w-1/3"><span class="font-bold">Email Address: <p>{{ userEmail }}</p></span></li>
              <li class="w-full md:w-1/3" v-if="qrCode"><span class="font-bold">QR Code: <p> <img :src="qrCode" alt="QR Code" /></p></span></li>
              <li class="w-full md:w-1/3"><span class="font-bold">Total Stamps: {{ stampCount }}/20<p></p></span></li>
            </ul>
        </div>
    </div>

    <div class="af flex h-screen"  v-if="isAdmin == true">
    <div class="bg-gray-200 w-64 h-60 afo flex-shrink-0" :class="{ 'hidden': !showSidebar }">
      <ul class="mt-8">
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer">
          <a href="#">Dashboard</a>
        </li>
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer" @click="scanner">
          <a href="#">QR Scanner</a>
        </li>
        <li class="px-6 py-2 text-red-700 hover:bg-gray-400 cursor-pointer" @click="signout">
          <a href="#" >Logout</a>
        </li>
      </ul>
    </div>
    <div class="bg-green-50 flex-1 p-10">
        <div class="af flex justify-between items-center mb-6">
          <h1 class="text-2xl font-bold">Welcome, Exhibitor!</h1>
        </div>
            <p>This is euro village account dashboard!</p>
            <ul class="mt-4 af flex flex-wrap">
              <li class="w-full md:w-1/3"><span class="font-bold">Email Address: <p>{{ userEmail }}</p></span></li>
              <li class="w-full md:w-1/3"><span class="font-bold">Scanned Guest: <p>{{ scanCount }}</p></span></li>
              
            </ul>
        </div>
    </div>

  </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from '../components/Navbar'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { collection, query, where, getDocs } from "firebase/firestore";
import Footer from "../components/Footer";
import QRCode from 'qrcode';
import jsQR from 'jsqr'
import { mapState } from "vuex";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification
} from 'firebase/auth'

export default {
  data() {
    return {
      userEmail: null,
      nameDisplay: null,
      qrCode: null,
      isAdmin: false,
      exhibitRole: '',
      error: '',
      scanResult: '',
      showSidebar: true,
      stampCount: 0,
      scanCount: 0,
      scanCountUpdated : false,
      dashBoardParamvalue: 0,
    };
  },
  async created() {
    // Get the current user's email
    // const user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        if(parseInt(this.$route.params.value) !== undefined){
          this.dashBoardParamvalue = parseInt(this.$route.params.value);
        }
        console.log("User is signed in.");
        this.initPage(user);
        this.generateQRCode(user.uid);
      } else {
        // User is signed out.
        console.log("User is signed out.");
        // alert("Please Log in");
        this.$router.replace('/login')
      }
    });
  },
  components: {
    Navbar,
    Footer
  },
  mounted() {
    if(this.isAdmin){
      navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        // Store the stream so it can be stopped later
        this.stream = stream
        // Set the video element to use the camera stream
        this.$refs.video.srcObject = stream
      })
      .catch(error => {
        console.error('Failed to get camera stream', error)
      })
    }
  },
  methods: {
    signout () {
      firebase
        .auth()
        .signOut()
        .then(user => {
          // alert('Logout Successfully')
          this.$router.replace('/')
        })
        .catch(error => {
          alert('Error : ' + err.message)
        })
    },
    stamps () {
          this.$router.replace('/stamps')
    },
    scanner () {
          this.$router.replace('/scanner')
    },
    updateCountAccount(user){
      let uid = user.uid;
      
      if(this.exhibitRole === "austria"){
        uid = "mkdl3aAnh0UKr3Zmjt7LIKbUSfg1";
      }
      if(this.exhibitRole === "belgium"){
        uid = "uKeUKLiRbrbsHuoGF1mtUWtdkM93";
      }
      if(this.exhibitRole === "czech"){
        uid = "WURFIE3Hh9cZlFeEYRBCFGRMJas2";
      }
      if(this.exhibitRole === "denmark"){
        uid = "h4JOHLj2NnThTsLq1ZpPszt8XVl1";
      }
      if(this.exhibitRole === "france"){
        uid = "nujUAhrPxuhSIjLCygXEl6YIqOv1";
      }
      if(this.exhibitRole === "germany"){
        uid = "xkapjyjmwoY9Yrr7jjWuutyPB432";
      }
      if(this.exhibitRole === "hungary"){
        uid = "bB4Ms67hyueg06wOHW94OyzUUsF3";
      }
      if(this.exhibitRole === "italy"){
        uid = "nLVc3wzACNbd45ckxqkAcvtut7A3";
      }
      if(this.exhibitRole === "netherlands"){
        uid = "pD7LPdZAVzf1QPIjeNTLJlsXqUj1";
      }
      if(this.exhibitRole === "others1"){
        uid = "w3HTlvuX9RSPq5tpWt99xS3WNqJ3";
      }
      if(this.exhibitRole === "others3"){
        uid = "HkEIl1ELwRgcVqDfWUKrjYPv2vs1";
      }
      if(this.exhibitRole === "others4"){
        uid = "9OozUPRIovRqO90WF2J2Hd8YGq42";
      }
      if(this.exhibitRole === "others5"){
        uid = "5K6iVpUdT6Qb5Z5GTE4vVfzsjEL2";
      }
      if(this.exhibitRole === "others6"){
        uid = "gJURXSR8oKNNK8CyYIZYJTtEzsq2";
      }
      if(this.exhibitRole === "others7"){
        uid = "WdK7J8vfA9gVj6sM0ZcevfGHsh92";
      }
      if(this.exhibitRole === "poland"){
        uid = "l1UepgFvOThoEVLllxbLIYrqVpz2";
      }
      if(this.exhibitRole === "romania"){
        uid = "bxmC85W4swOz4WdFyG2T1j7FoCh2";
      }
      if(this.exhibitRole === "spain"){
        uid = "JLOj2iUZNgWhXdoo7J4xIBu3PqY2";
      }
      if(this.exhibitRole === "ukraine"){
        uid = "iVgj6EXg6vYeFjQPDL9UYtf0GRg2";
      }
      const userRef = firebase.firestore().collection("users").doc(uid);
      userRef.get().then((doc) => {
            if (doc.exists) {
            const data = doc.data();
            if(!this.scanCountUpdated && data.role !== "normal"){
              if(data.count === "" || undefined){
                data.count = "1";
                this.scanCount = "1";
                this.scanCountUpdated = true;
              }else{
                  const parsedInt = parseInt(data.count);

                  if(data.count !== "NaN"){
                    let finalCount = String(parsedInt + 1);
                     this.scanCount = finalCount;
                     data.count = this.scanCount;
                     this.scanCountUpdated = true;
                  }
              }
              userRef.update(data).then(() => {
                this.$router.replace('/dashboard')
                }).catch((error) => {
                console.error("Error Scanned Data", error);
                });    

              }
            }
           
      }).catch((error) => {
        console.error("Error getting user data:", error);
      });
    },
    initPage (user){
      this.userEmail = user.email;
      this.nameDisplay = user.displayName;
      console.log(user.uid);
      let userId = user.uid;

      if(userId === "QAoIt2EFyRYqsSgIw1coa4XnO3d2"){
        userId = "uKeUKLiRbrbsHuoGF1mtUWtdkM93";
      }

      if(userId === "QSeTv6EyR8PlCNWuRXK9o2llQDv2"){
        userId = "h4JOHLj2NnThTsLq1ZpPszt8XVl1";
      }

      if(userId === "6JupTKU48wPRLjdUbxt22IctkbS2" || userId === "lTI3Hdo1MaVDUzaLpAitRT4fhVB2"){
        userId = "nujUAhrPxuhSIjLCygXEl6YIqOv1";
      }

      if(userId === "Y6ijj41CM1ef8qqX2iP5bZhw2m83"){
        userId = "xkapjyjmwoY9Yrr7jjWuutyPB432";
      }

      if(userId === "lkQxz5lWodhOdsNukkDxmP3rvKY2"){
        userId = "pD7LPdZAVzf1QPIjeNTLJlsXqUj1";
      }

      if(userId === "RaRai8lTEqRtIuDLagYrq1WR3pm2"){
        userId = "w3HTlvuX9RSPq5tpWt99xS3WNqJ3";
      } 

      if(userId === "0pHOnHqoUne8ZHhZ4UVj7zczeYg2"){
        userId = "gJURXSR8oKNNK8CyYIZYJTtEzsq2";
      } 

      if(userId === "233KxvJdsDMKZmZ4yDKztDCOsgs2" || userId === "GzeNn7nQ6qMQ4yk9wtuddYk6i3r2"){
        userId = "l1UepgFvOThoEVLllxbLIYrqVpz2";
      } 

      if(userId === "WCf3JDIjplMcQGj189ENKXodW0v1"){
        userId = "bxmC85W4swOz4WdFyG2T1j7FoCh2";
      }   
      console.log(userId);
      const userRef = firebase.firestore().collection("users").doc(userId);

      userRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const role = data.role;
          const belgium = data.belgium;
          const czech = data.czech;
          const denmark = data.denmark;
          const germany = data.germany;
          const spain = data.spain;
          const france = data.france;
          const italy = data.italy;
          const hungary = data.hungary;
          const netherlands = data.netherlands;
          const austria = data.austria;
          const poland = data.poland;
          const romania = data.romania;
          const finland = data.finland;
          const ukraine = data.ukraine;
          const others1 = data.others1;
          const others2 = data.others2;
          const others3 = data.others3;
          const others4 = data.others4;
          const others5 = data.others5; 
          const others6 = data.others6;
          const others7 = data.others7; 
          if(role !== "normal"){
            this.isAdmin = true;
            this.exhibitRole = data.role;
            if(this.dashBoardParamvalue === 1){
              console.log("pasol");
              this.updateCountAccount(user);
            }else{
              this.scanCount = data.count;
            }
          }

          if(belgium !== ""){
            this.stampCount++;
          }
          if(czech !== ""){
            this.stampCount++;
          }
          if(denmark !== ""){
            this.stampCount++;
          }

          if(germany !== ""){
            this.stampCount++;
          }

          if(spain !== ""){
            this.stampCount++;
          }

          if(france !== ""){
            this.stampCount++;
          }

          if(italy !== ""){
            this.stampCount++;
          }

          if(hungary !== ""){
            this.stampCount++;
          }

          if(netherlands !== ""){
            this.stampCount++;
          }

          if(austria !== ""){
            this.stampCount++;
          }

          if(poland !== ""){
            this.stampCount++;
          }

          if(romania !== ""){
            this.stampCount++;
          }

          if(finland !== ""){
            this.stampCount++;
          }

          if(ukraine !== ""){
            this.stampCount++;
          }

          if(others1 !== ""){
            this.stampCount++;
          }

          if(others2 !== ""){
            this.stampCount++;
          }
          if(others3 !== ""){
            this.stampCount++;
          }
          if(others4 !== ""){
            this.stampCount++;
          }
          if(others5 !== ""){
            this.stampCount++;
          }
          if(others6 !== ""){
            this.stampCount++;
          }
          if(others7 !== ""){
            this.stampCount++;
          }



          console.log("User's favorite color is:", role);
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.error("Error getting user data:", error);
      });
    },
    generateQRCode(text) {
      QRCode.toDataURL(text, {
        width: 320,
        height: 320,
        margin: 0
        })
        .then(url => {
          this.qrCode = url;
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
}
</script>

<style>
.breadcrumb {
  padding-left:12%;
}
ul.breadcrumb{
  margin-top: .5%;
  margin-bottom: .5%;
}

@media screen and (max-width: 640px) {
  .af {
    flex-direction: column;
  }

  .afo {
    width: 100%;
  }

  .mt-8 {
    margin-top: 1rem;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .w-full {
    width: 100%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }
}
</style>
