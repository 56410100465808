<template>
        <div class="container mx-auto flex flex-col md:flex-row items-center pt-10 pb-10">
          <div class="w-full md:w-1/2 flex flex-col items-center pt-10 pb-10">
            <div class="flex flex-col items-center">
            <h2 class="title-section text-2xl">
            Euro Village
          </h2>
          </div>
          <div class="flex flex-col items-center">
            <h2 class="title-section text-s lg:text-1xl">
            3-4 June 2023
          </h2> 
          </div>
          <div class="flex flex-col items-center">
            <h2 class="title-section text-s lg:text-1xl">
          Capital Commons Park, Camino Verde Road,
        </h2> 
          </div>
          <div class="flex flex-col items-center">
            <h2 class="title-section text-s lg:text-1xl">
          Pasig City
        </h2> 
          </div>
          <div class="divider mx-auto"></div>
          <br />
          <div>
            <p class="blue-text">
              The Euro Village - a one-stop shop highlighting the best of EU’s food, products, lifestyle, sports demonstrations, tourism, culture, and languages while promoting sustainability.</p>
          </div>
          <br />
          <div>
            <p class="blue-text">
              Open to the public for FREE from 3 to 4 June 2023 from 4 pm to 12 midnight.</p>
            </div> 
            
          </div>
    <div class="w-full md:w-1/2 flex flex-col items-center pt-10 pb-10">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.4072737358924!2d121.06130667563559!3d14.575853685907568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c86cf2a8b461%3A0xd95a88b69deadb53!2sCapitol%20Commons%20Park!5e0!3m2!1sfil!2sph!4v1685604712415!5m2!1sfil!2sph" width="400" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </div>
        </div>

        <div class="flex justify-center items-center mx-auto pt-10 pb-10">
          <div class="flex flex-col items-center">
            <h2 class="title-section text-2xl">
            Vicinity Map
          </h2>
          <div class="divider mx-auto"></div>

          <img src="../assets/eventmap.jpg" alt="Event Map">

          </div>
          
        </div>

</template>

<script>
export default {
  data() {
    return {
      scrollPosition: 0,
    };
  },

  methods: {
    handleScroll(e) {
      this.scrollPosition = e.target.scrollTop;

      if (this.scrollPosition > 100) {
        console.log("UP");
      } else {
        console.log("DOWN");
      }
    },
  },
};
</script>

<style>
h2.title-section {
  color: #04277a;
}

.blue-text{
  color: #04277a;
}

br{
   display:block !important;
}
</style>
