<template>
  <Navbar />
  <Header />
  <!-- <Services /> -->
  <About />

  <div class="flex justify-center items-center mx-auto pt-10 pb-10">
          <div class="flex flex-col items-center">
            <h2 class="title-section text-2xl">
            Digital Passport
          </h2>
          <div class="divider mx-auto"></div>
          </div>
          
        </div>
  <Carousel />
  <Exhibitors />

  <!-- <Blog :blog="blog" /> -->
  <Footer />
</template>

<script>
import Navbar from "./Navbar.vue";
import Header from "./Header.vue";
import Carousel from "./Carousel.vue";
import About from "./About.vue";
import Projects from "./Projects.vue";
import Contact from "./Contact.vue";
import Exhibitors from "./Exhibitors.vue";
import Footer from "./Footer.vue";

export default {
  name: "Home",
  data() {
    return {};
  },

  props: {
    blog: Array,
  },

  components: {
    Navbar,
    Header,
    Services,
    About,
    Projects,
    Contact,
    Exhibitors,
    Footer,
    Carousel,
  },
};
</script>

<style>
h2.title-section {
  color: #04277a;
}

img.flag{
 width:64px;
 height: 40px;
 border: 1px;
}

</style>
