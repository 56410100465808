<template>
  <Navbar />
  <!-- <div class="container mt-5">
    <div class="page-banner1">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-6"> -->
          <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ul>
          </nav>

          <div class="py-1 page-section">
    <div class="container">
      <button class="block md:hidden" @click="showSidebar = !showSidebar">
              <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0zm0 6h20v2H0zm0 6h20v2H0z"/></svg>
            </button>
  <div class="af flex h-screen"  v-if="isAdmin == false">
    <div class="bg-gray-200 w-64 h-60 afo flex-shrink-0" :class="{ 'hidden': !showSidebar }">
      <ul class="mt-8">
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer">
          Stamps
        </li>
        <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer" @click="dashboard">
          Dashboard
        </li>
        <li class="px-6 py-2 text-red-700 hover:bg-gray-400 cursor-pointer" @click="signout">
          Logout
        </li>
      </ul>
    </div>
      <div class="flex-1 p-10">
          <div class="af flex justify-between items-center mb-6">
            <h1 class="text-2xl font-bold">Hi {{ nameDisplay }}!</h1>
          </div>
            <span class="font-bold">Total Stamps count: {{ stampCount }}/20<p></p></span>
            <span>Collect all stamps by getting your QR code scanned when you complete an activity at the different booths in Euro Village.<p></p></span>

            <div class="overflow-x-auto">
            <table class="aftable divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Exhibitor
                  </th>
                  <th class="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center ">
                      <img src="../assets/stamps/Belgium.png" :class="belgiumShade" alt="Belgium">
                      <div class="pl-7 text-sm text-gray-900">Belgium</div>
                    </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!belgiumBool">Not Completed</span>
                      <span class="text-green-500" v-if="belgiumBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Czech_Republic.png" :class="czechShade" alt="Czech Republic">
                      <div class="pl-7 text-sm text-gray-900">The Czech Republic</div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <span class="text-red-500" v-if="!czechBool">Not Completed</span>
                      <span class="text-green-500" v-if="czechBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Denmark.png" :class="denmarkShade" alt="Denmark">
                      <div class="pl-7 text-sm text-gray-900">Denmark</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!denmarkBool">Not Completed</span>
                      <span class="text-green-500" v-if="denmarkBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Germany.png" :class="germanyShade" alt="Germany">
                      <div class="pl-7 text-sm text-gray-900">Germany</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!germanyBool">Not Completed</span>
                      <span class="text-green-500" v-if="germanyBool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/France.png" :class="franceShade" alt="France">
                      <div class="pl-7 text-sm text-gray-900">France</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!franceBool">Not Completed</span>
                      <span class="text-green-500" v-if="franceBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Italy.png" :class="italyShade" alt="Italy">
                      <div class="pl-7 text-sm text-gray-900">Italy</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!italyBool">Not Completed</span>
                      <span class="text-green-500" v-if="italyBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Hungary.png" :class="hungaryShade" alt="Hungary">
                      <div class="pl-7 text-sm text-gray-900">Hungary</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!hungaryBool">Not Completed</span>
                      <span class="text-green-500" v-if="hungaryBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Netherlands.png" :class="netherShade" alt="Netherlands">
                      <div class="pl-7 text-sm text-gray-900">The Netherlands</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!netherlandsBool">Not Completed</span>
                      <span class="text-green-500" v-if="netherlandsBool">Completed</span>
                    </div>
                  </td>
                </tr>
 
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Poland.png" :class="polandShade" alt="Poland">
                      <div class="pl-7 text-sm text-gray-900">Poland</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!polandBool">Not Completed</span>
                      <span class="text-green-500" v-if="polandBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Romania.png" :class="romaniaShade" alt="Poland">
                      <div class="pl-7 text-sm text-gray-900">Romania</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!romaniaBool">Not Completed</span>
                      <span class="text-green-500" v-if="romaniaBool">Completed</span>
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Finland.png" :class="finlandShade" alt="Finland">
                      <div class="pl-7 text-sm text-gray-900">Finland</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!finlandBool">Not Completed</span>
                      <span class="text-green-500" v-if="finlandBool">Completed</span>
                    </div>
                  </td>
                </tr> -->
                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Ukraine.png" :class="ukraineShade" alt="Ukraine">
                      <div class="pl-7 text-sm text-gray-900">Ukraine</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!ukraineBool">Not Completed</span>
                      <span class="text-green-500" v-if="ukraineBool">Completed</span>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Alliance.png" :class="allianceShade" alt="Alliance">
                      <div class="pl-7 text-sm text-gray-900">Alliance</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others1Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others1Bool">Completed</span>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Goethe.png" :class="geotheShade" alt="Geothe">
                      <div class="pl-7 text-sm text-gray-900">Geothe</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others2Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others2Bool">Completed</span>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Instituto.png" :class="institutoShade" alt="Instituto">
                      <div class="pl-7 text-sm text-gray-900">Instituto</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others3Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others3Bool">Completed</span>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/PIA.png" :class="piaShade" alt="PIA">
                      <div class="pl-7 text-sm text-gray-900">PIA</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others4Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others4Bool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr><!-- ECO NEST-->
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Econest.jpg" :class="others5BoolShade" alt="econest">
                      <div class="pl-7 text-sm text-gray-900">ECO Nest</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others5Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others5Bool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr> <!-- Greenspace-->
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/Green_Space.jpg" :class="austriaShade" alt="Austria">
                      <div class="pl-7 text-sm text-gray-900">Greenspace</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!austriaBool">Not Completed</span>
                      <span class="text-green-500" v-if="austriaBool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr><!-- SOS-->
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/SOS_Philippines.jpg" :class="spainShade" alt="Spain">
                      <div class="pl-7 text-sm text-gray-900">SOS Philippines</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!spainBool">Not Completed</span>
                      <span class="text-green-500" v-if="spainBool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr><!-- Host-->
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/stamps/EU.png" :class="others6BoolShade" alt="Spain">
                      <div class="pl-7 text-sm text-gray-900">European Union</div>
                      </div>  
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others6Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others6Bool">Completed</span>
                    </div>
                  </td>
                </tr>

                <tr><!-- Ortigas Land-->
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900 flex-col justify-center items-center">
                      <img src="../assets/venue_partner/OrtigasCommunityCard.png" :class="others7BoolShade" alt="Spain">
                      <div class="pl-7 text-sm text-gray-900">Ortigas Community Card</div>
                      </div>
                  </td>
                  
                  <td class="whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span class="text-red-500" v-if="!others7Bool">Not Completed</span>
                      <span class="text-green-500" v-if="others7Bool">Completed</span>
                    </div>
                  </td>
                </tr>


              </tbody>
            </table>
          </div>
      </div>
    </div>

  </div>
    </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
    <Footer />
</template>

<script>
import Navbar from '../components/Navbar'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import Footer from "../components/Footer";
import QRCode from 'qrcode';
import jsQR from 'jsqr'
import { mapState } from "vuex";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification
} from 'firebase/auth'

export default {
  data() {
    return {
      userEmail: null,
      nameDisplay: null,
      qrCode: null,
      isAdmin: false,
      error: '',
      scanResult: '',
      showSidebar: true,
      stampCount: 0,
      belgiumBool: false,
      czechBool: false, 
      denmarkBool: false,
      germanyBool: false,
      spainBool: false,
      franceBool: false,
      italyBool: false,
      hungaryBool: false,
      netherlandsBool: false,
      austriaBool: false,
      polandBool: false,
      romaniaBool: false,
      finlandBool: false,
      ukraineBool: false,
      others1Bool: false,
      others2Bool: false,
      others3Bool: false,
      others4Bool: false,
      others5Bool: false,
      others6Bool: false,
      others7Bool: false
    };
  },
  created() {
    // Get the current user's email
    // const user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        console.log("User is signed in.");
        this.initPage(user);
        this.generateQRCode(this.userEmail);
      } else {
        // User is signed out.
        console.log("User is signed out.");
        // alert("Please Log in");
        this.$router.replace('/login')
      }
    });
  },
  components: {
    Navbar,
    Footer
  },
  mounted() {
    // Request permission to use the camera
    if(this.isAdmin){
      navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        // Store the stream so it can be stopped later
        this.stream = stream
        // Set the video element to use the camera stream
        this.$refs.video.srcObject = stream
      })
      .catch(error => {
        console.error('Failed to get camera stream', error)
      })
    }
  }, 
  computed: {
    allianceShade() {
      return {
        'stamp': this.others1Bool,
        'stampShade': !this.others1Bool,
      };
    },
    geotheShade() {
      return {
        'stamp': this.others2Bool,
        'stampShade': !this.others2Bool,
      };
    },
    institutoShade() {
      return {
        'stamp': this.others3Bool,
        'stampShade': !this.others3Bool,
      };
    },
    piaShade() {
      return {
        'stamp': this.others4Bool,
        'stampShade': !this.others4Bool,
      };
    },
    belgiumShade() {
      return {
        'stamp': this.belgiumBool,
        'stampShade': !this.belgiumBool,
      };
    },
    czechShade() {
      return {
        'stamp': this.czechBool,
        'stampShade': !this.czechBool,
      };
    },
    denmarkShade() {
      return {
        'stamp': this.denmarkBool,
        'stampShade': !this.denmarkBool,
      };
    },
    germanyShade() {
      return {
        'stamp': this.germanyBool,
        'stampShade': !this.germanyBool,
      };
    },
    spainShade() {
      return {
        'stamp': this.spainBool,
        'stampShade': !this.spainBool,
      };
    },
    franceShade() {
      return {
        'stamp': this.franceBool,
        'stampShade': !this.franceBool,
      };
    },
    italyShade() {
      return {
        'stamp': this.italyBool,
        'stampShade': !this.italyBool,
      };
    },
    hungaryShade() {
      return {
        'stamp': this.hungaryBool,
        'stampShade': !this.hungaryBool,
      };
    },
    netherShade() {
      return {
        'stamp': this.netherlandsBool,
        'stampShade': !this.netherlandsBool,
      };
    },
    austriaShade() {
      return {
        'stamp': this.austriaBool,
        'stampShade': !this.austriaBool,
      };
    },
    polandShade() {
      return {
        'stamp': this.polandBool,
        'stampShade': !this.polandBool,
      };
    },
    romaniaShade() {
      return {
        'stamp': this.romaniaBool,
        'stampShade': !this.romaniaBool,
      };
    },
    finlandShade() {
      return {
        'stamp': this.finlandBool,
        'stampShade': !this.finlandBool,
      };
    },
    ukraineShade() {
      return {
        'stamp': this.ukraineBool,
        'stampShade': !this.ukraineBool,
      };
    },
    others5BoolShade() {
      return {
        'stamp': this.others5Bool,
        'stampShade': !this.others5Bool,
      };
    },
    others6BoolShade() {
      return {
        'stamp': this.others6Bool,
        'stampShade': !this.others6Bool,
      };
    },
    others7BoolShade() {
      return {
        'stamp': this.others7Bool,
        'stampShade': !this.others7Bool,
      };
    },
  },
  methods: {
    signout () {
      firebase
        .auth()
        .signOut()
        .then(user => {
          // alert('Logout Successfully')
          this.$router.replace('/dashboard')
        })
        .catch(error => {
          alert('Error : ' + err.message)
        })
    },
    dashboard () {
          this.$router.replace('/dashboard')
    },
    initPage (user){
      this.userEmail = user.email;
      this.nameDisplay = user.displayName;
      const uid = user.uid;
      const userRef = firebase.firestore().collection("users").doc(uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const role = data.role;
          const belgium = data.belgium;
          const czech = data.czech;
          const denmark = data.denmark;
          const germany = data.germany;
          const spain = data.spain;
          const france = data.france;
          const italy = data.italy;
          const hungary = data.hungary;
          const netherlands = data.netherlands;
          const austria = data.austria;
          const poland = data.poland;
          const romania = data.romania;
          const finland = data.finland;
          const ukraine = data.ukraine;
          const others1 = data.others1;
          const others2 = data.others2;
          const others3 = data.others3;
          const others4 = data.others4;
          const others5 = data.others5;
          const others6 = data.others6;
          const others7 = data.others7;

          if(role == "admin"){
            this.isAdmin = true;
          }

          if(belgium !== "" && belgium != undefined){
            this.stampCount++;
            this.belgiumBool= true;
          }
          if(czech !== "" && czech != undefined){
            this.stampCount++;
            this.czechBool= true;
          }
          if(denmark !== "" && denmark != undefined){
            this.stampCount++;
            this.denmarkBool= true;
          }

          if(germany !== "" && germany != undefined){
            this.stampCount++;
            this.germanyBool= true;
          }

          if(spain !== "" && spain != undefined){
            this.stampCount++;
            this.spainBool= true;
          }

          if(france !== "" && france != undefined){
            this.stampCount++;
            this.franceBool= true;
          }

          if(italy !== "" && italy != undefined){
            this.stampCount++;
            this.italyBool= true;
          }

          if(hungary !== "" && hungary != undefined){
            this.stampCount++;
            this.hungaryBool= true;
          }

          if(netherlands !== "" && netherlands != undefined){
            this.stampCount++;
            this.netherlandsBool= true;
          }

          if(austria !== "" && austria != undefined){
            this.stampCount++;
            this.austriaBool= true;
          }

          if(poland !== "" && poland != undefined){
            this.stampCount++;
            this.polandBool= true;
          }

          if(romania !== "" && romania != undefined){
            this.stampCount++;
            this.romaniaBool= true;
          }

          if(finland !== "" && finland != undefined){
            this.stampCount++;
            this.finlandBool= true;
          }

          if(ukraine !== "" && ukraine != undefined){
            this.stampCount++;
            this.ukraineBool= true;
          }

          if(others1 !== "" && others1 != undefined){
            this.stampCount++;
            this.others1Bool= true;
          }

          if(others2 !== "" && others2 != undefined){
            this.stampCount++;
            this.others2Bool= true;
          }

          if(others3 !== "" && others3 != undefined){
            this.stampCount++;
            this.others3Bool= true;
          }

          if(others4 !== "" && others4 != undefined){
            this.stampCount++;
            this.others4Bool= true;
          }

          if(others5 !== "" && others5 != undefined){
            this.stampCount++;
            this.others5Bool= true;
          }

          if(others6 !== "" && others6 != undefined){
            this.stampCount++;
            this.others6Bool= true;
          }
          
          if(others7 !== "" && others7 != undefined){
            this.stampCount++;
            this.others7Bool= true;
          }
          
        } else {
          console.log("No such document!");
        }
      }).catch((error) => {
        console.error("Error getting user data:", error);
      });
    },
    generateQRCode(text) {
      QRCode.toDataURL(text)
        .then(url => {
          this.qrCode = url;
        })
        .catch(err => {
          console.error(err);
        });
    },
    startScan() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" }}})
          .then(stream => {
            this.$refs.video.srcObject = stream;
            this.$refs.video.play();
            this.startVideo();
          })
          .catch(error => {
            this.error = error.message;
          });
      } else {
        this.error = 'Camera not available';
      }
    },
    startVideo() {
      this.$refs.video.play();
      this.$refs.video.removeEventListener('click', this.startVideo);
    },
    tick() {
      if (!this.$refs.video.paused && !this.$refs.video.ended) {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        canvas.width = this.$refs.video.videoWidth;
        canvas.height = this.$refs.video.videoHeight;
        ctx.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);
        if (code) {
          this.scanResult = code.data;
          this.$refs.video.pause();
        } else {
          requestAnimationFrame(this.tick);
        }
      }
    }
  }
}
</script>

<style>
.breadcrumb {
  padding-left:12%;
}
ul.breadcrumb{
  margin-top: .5%;
  margin-bottom: .5%;
}

img.stamp{
  max-width: 180px;
  max-width: 180px;
}

img.stampShade{
  max-width: 180px;
  max-width: 180px;
  opacity: 10%;
}

table.aftable{
  width: 30%;
}

@media screen and (max-width: 640px) {
  .af {
    flex-direction: column;
  }

  .afo {
    width: 100%;
  }

  .mt-8 {
    margin-top: 1rem;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .w-full {
    width: 100%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }

  table.aftable{
  width: 100%;
  }

}

div.af{
  overflow: auto;
}
</style>
