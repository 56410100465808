<template>
    <div class="container">
        <div class="relative ">
      <div class="carousel">
        <div v-for="(image, index) in images" :key="index" :class="getImageClasses(index)">
          <img :src="image" alt="Carousel Image" class="w-full h-auto">
        </div>
      </div>
  
      <div class="carousel-nav">
        <button class="carousel-nav-btn" @click="prevImage">
          <i class="fas fa-chevron-left"></i>
          <svg fill="#000000" width="20px" height="20px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.95 5.843L7.828 7.964l2.122 2.122L8.536 11.5 5 7.964 8.536 4.43 9.95 5.843zM8 16c-4.418 0-8-3.59-8-8 0-4.418 3.59-8 8-8 4.418 0 8 3.59 8 8 0 4.418-3.59 8-8 8zm0-2c3.307 0 6-2.686 6-6 0-3.307-2.686-6-6-6-3.307 0-6 2.686-6 6 0 3.307 2.686 6 6 6z" fill-rule="evenodd"/>
        </svg>
        </button>
        <button class="carousel-nav-btn" @click="nextImage">
          <i class="fas fa-chevron-right"></i>
          <svg fill="#000000" width="20px" height="20x" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.05 10.157l2.122-2.121L6.05 5.914 7.464 4.5 11 8.036 7.464 11.57 6.05 10.157zM8 0c4.418 0 8 3.59 8 8 0 4.418-3.59 8-8 8-4.418 0-8-3.59-8-8 0-4.418 3.59-8 8-8zm0 2C4.693 2 2 4.686 2 8c0 3.307 2.686 6 6 6 3.307 0 6-2.686 6-6 0-3.307-2.686-6-6-6z" fill-rule="evenodd"/>
            </svg>
        </button>
      </div>
    </div>

    </div>
   
  </template>
  
  <script>

import image1 from '@/assets/how_to/1.jpg';
import image2 from '@/assets/how_to/2.jpg';
import image3 from '@/assets/how_to/3.jpg';
import image4 from '@/assets/how_to/1-3.jpg';
import image5 from '@/assets/how_to/4.jpg';
import image6 from '@/assets/how_to/5.jpg';
import image7 from '@/assets/how_to/6.jpg';
import image8 from '@/assets/how_to/7.jpg';


  export default {
    data() {
      return {
        images: [image1,image2,image3,image4],
        currentImageIndex: 0,
      };
    },
    methods: {
    getImageClasses(index) {
      return {
        'carousel-slide': true,
        'opacity-100': index === this.currentImageIndex,
        'opacity-0': index !== this.currentImageIndex,
      };
    },
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    prevImage() {
      this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
    },
  },
  mounted() {
    setInterval(() => {
      this.nextImage();
    }, 5000); // Auto next every 5 seconds (5000 milliseconds)
  },
};
</script>
  
  <style>

.carousel {
  display: flex;
  transition: all 0.5s;
  height: 700px; /* Adjust the height as needed */
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

@media (max-width: 640px) {
  .carousel {
    height: 400px; /* Reduced height for mobile */
  }
}


.carousel-slide {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

.carousel-slide.active {
  opacity: 1;
}

.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.carousel-nav-btn {
  background-color: rgb(255, 255, 255);
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
  opacity: 30%;
}

.carousel-nav-btn i {
  font-size: 1.5rem;
}
  </style>
  