<template>

<div id="readerQR" class="vue-qr-reader__container" ref="container">
                    <video
                      v-show="showPlay"
                      class="source"
                      ref="video"
                      :width="videoWH.width"
                      :height="videoWH.height"
                      controls
                    ></video>
                    <canvas v-show="!showPlay" ref="canvas"></canvas>
                    <button v-show="showPlay" @click="run">Play!</button>
                  </div>
</template>
<script>
import jsQR from "jsqr";
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

export default {
    props: {
      useBackCamera: {
        type: Boolean,
        default: true
      },
      stopOnScanned: {
        type: Boolean,
        default: true
      },
      drawOnFound: {
        type: Boolean,
        default: true
      },
      lineColor: {
        type: String,
        default: "#FF3B58"
      },
      lineWidth: {
        type: Number,
        default: 2
      },
      videoWidth: {
        type: Number,
        default: 320
      },
      videoHeight: {
        type: Number,
        default: 240
      },
      responsive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showPlay: false,
        containerWidth: null,
        scannedQr : false,
        exhibitorCode : ""
      };
    },
    created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        console.log("User is signed in.");
        this.initPage(user);
      } else {
        // User is signed out.
        console.log("User is signed out.");
        // alert("Please Log in");
        this.$router.replace('/login')
      }
    });
    },
    computed: {
      videoWH() {
        if (this.containerWidth) {
          const width = this.containerWidth;
          const height = width * 0.75;
          return { width, height };
        }
        return { width: this.videoWidth, height: this.videoHeight };
      }
    },
    methods: {
      drawLine(begin, end) {
        this.canvas.beginPath();
        this.canvas.moveTo(begin.x, begin.y);
        this.canvas.lineTo(end.x, end.y);
        this.canvas.lineWidth = this.lineWidth;
        this.canvas.strokeStyle = this.lineColor;
        this.canvas.stroke();
      },
      drawBox(l) {
        if (this.drawOnFound) {
          this.drawLine(l.topLeftCorner, l.topRightCorner);
          this.drawLine(l.topRightCorner, l.bottomRightCorner);
          this.drawLine(l.bottomRightCorner, l.bottomLeftCorner);
          this.drawLine(l.bottomLeftCorner, l.topLeftCorner);
        }
      },
      tick() {
        if (
          this.$refs.video &&
          this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA
        ) {
          this.$refs.canvas.height = this.videoWH.height;
          this.$refs.canvas.width = this.videoWH.width;
          this.canvas.drawImage(
            this.$refs.video,
            0,
            0,
            this.$refs.canvas.width,
            this.$refs.canvas.height
          );
          const imageData = this.canvas.getImageData(
            0,
            0,
            this.$refs.canvas.width,
            this.$refs.canvas.height
          );
          let code = false;
          try {
            code = jsQR(imageData.data, imageData.width, imageData.height);
          } catch (e) {
            console.error(e);
          }
          if (code) {
            this.drawBox(code.location);
            this.found(code.data);
            this.updateBadge(code.data);
          }
        }
        if(!this.scannedQr){
            this.run();
        }
       
      },
      updateBadge(email){
        if(email !== ""){
            this.scannedQr = true;
            this.stampUpdate(email);
        }
      },
      stampUpdate(scannedValue){
        let isScanned = false;
        const userRef = firebase.firestore().collection("users").doc(scannedValue);
            userRef.get().then((doc) => {
            if (doc.exists) {
            const data = doc.data();

            if(this.exhibitorCode === "belgium"){
              if(data.belgium === "1"){
                isScanned = true;
              } else {
                data.belgium = "1";
              } 
            }
            if(this.exhibitorCode === "czech"){
              if(data.czech === "1"){
                isScanned = true;
              } else {
                data.czech = "1";
              } 
            }
            if(this.exhibitorCode === "denmark"){
              if(data.denmark === "1"){
                isScanned = true;
              } else {
                data.denmark = "1";
              } 
            }

            if(this.exhibitorCode === "germany"){
              if(data.germany === "1"){
                isScanned = true;
              } else {
                data.germany = "1";
              } 
            }

            if(this.exhibitorCode === "spain"){
              if(data.spain === "1"){
                isScanned = true;
              } else {
                data.spain = "1";
              } 
            }

            if(this.exhibitorCode === "france"){
              if(data.france === "1"){
                isScanned = true;
              } else {
                data.france = "1";
              } 
            }

            if(this.exhibitorCode === "italy"){
              if(data.italy === "1"){
                isScanned = true;
              } else {
                data.italy = "1";
              } 
            }

            if(this.exhibitorCode === "hungary"){
              if(data.hungary === "1"){
                isScanned = true;
              } else {
                data.hungary = "1";
              } 
            }

            if(this.exhibitorCode === "netherlands"){
              if(data.netherlands === "1"){
                isScanned = true;
              } else {
                data.netherlands = "1";
              } 
            }

            if(this.exhibitorCode === "austria"){
              if(data.austria === "1"){
                isScanned = true;
              } else {
                data.austria = "1";
              } 
            }

            if(this.exhibitorCode === "poland"){
              if(data.poland === "1"){
                isScanned = true;
              } else {
                data.poland = "1";
              } 
            }

            if(this.exhibitorCode === "romania"){
              if(data.romania === "1"){
                isScanned = true;
              } else {
                data.romania = "1";
              } 
            }

            if(this.exhibitorCode === "finland"){
              if(data.finland === "1"){
                isScanned = true;
              } else {
                data.finland = "1";
              } 
            }

            if(this.exhibitorCode === "ukraine"){
              if(data.ukraine === "1"){
                isScanned = true;
              } else {
                data.ukraine = "1";
              } 
            }

            if(this.exhibitorCode === "others1"){
              if(data.others1 === "1"){
                isScanned = true;
              } else {
                data.others1 = "1";
              } 
            }

            if(this.exhibitorCode === "others2"){
              if(data.others2 === "1"){
                isScanned = true;
              } else {
                data.others2 = "1";
              } 
            }

            if(this.exhibitorCode === "others3"){
              if(data.others3 === "1"){
                isScanned = true;
              } else {
                data.others3 = "1";
              } 
            }

            if(this.exhibitorCode === "others4"){
              if(data.others4 === "1"){
                isScanned = true;
              } else {
                data.others4 = "1";
              } 
            }

            if(this.exhibitorCode === "others5"){
              if(data.others5 === "1"){
                isScanned = true;
              } else {
                data.others5 = "1";
              } 
            }

            if(this.exhibitorCode === "others6"){
              if(data.others6 === "1"){
                isScanned = true;
              } else {
                data.others6 = "1";
              } 
            }

            if(this.exhibitorCode === "others7"){
              if(data.others7 === "1"){
                isScanned = true;
              } else {
                data.others7 = "1";
              } 
            }
            
                userRef.update(data).then(() => {
                    
                    if(isScanned){
                      alert("Already Scanned");
                      this.$router.replace('/dashboard');
                    }else{
                      alert("Stamped Successfully");
                      this.$router.replace('/dashboard/1');
                    }
                    
                    }).catch((error) => {
                    console.error("Error Scanned Data", error);
                    });    
            }
        }).catch((error) => {
            console.error("Error getting user data:", error);
        });
      },
      setup() {
        if (this.responsive) {
          this.$nextTick(() => {
            this.containerWidth = this.$refs.vidcontainer.clientWidth;
          });
        }
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          this.previousCode = null;
          this.parity = 0;
          this.active = true;
          this.canvas = this.$refs.canvas.getContext("2d");
          const facingMode = this.useBackCamera
            ? { exact: "environment" }
            : "user";
          const handleSuccess = stream => {
            if (this.$refs.video.srcObject !== undefined) {
              this.$refs.video.srcObject = stream;
            } else if (videoEl.mozSrcObject !== undefined) {
              this.$refs.video.mozSrcObject = stream;
            } else if (window.URL.createObjectURL) {
              this.$refs.video.src = window.URL.createObjectURL(stream);
            } else if (window.webkitURL) {
              this.$refs.video.src = window.webkitURL.createObjectURL(stream);
            } else {
              this.$refs.video.src = stream;
            }
            // iOS play inline
            this.$refs.video.playsInline = true;
            const playPromise = this.$refs.video.play();
            playPromise.catch(() => (this.showPlay = true));
            playPromise.then(this.run);
          };
          navigator.mediaDevices
            .getUserMedia({ video: { facingMode } })
            .then(handleSuccess)
            .catch(() => {
              navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(handleSuccess)
                .catch(error => {
                  this.$emit("error-captured", error);
                });
            });
        }
      },
      run() {
        if (this.active) {
          requestAnimationFrame(this.tick);
        }
      },
      found(code) {
        if (this.previousCode !== code) {
          this.previousCode = code;
        } else if (this.previousCode === code) {
          this.parity += 1;
        }
        if (this.parity > 2) {
          this.active = this.stopOnScanned ? false : true;
          this.parity = 0;
          this.$emit("code-scanned", code);
        }
      },
      fullStop() {
        if (this.$refs.video && this.$refs.video.srcObject) {
          this.$refs.video.srcObject.getTracks().forEach(t => t.stop());
        }
      },
      initPage (user){
      this.userEmail = user.email;
      this.nameDisplay = user.displayName;
      const uid = user.uid;
      const userRef = firebase.firestore().collection("users").doc(uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const role = data.role;
          if(role !== "normal"){
            this.exhibitorCode = role;
          }
        }
      }).catch((error) => {
        console.error("Error getting user data:", error);
      });
    }
    },
    mounted() {
      const targetDiv = document.getElementById('readerQR');
      targetDiv.scrollIntoView();
      this.setup();
    },
    beforeDestroy() {
      this.fullStop();
    },
    watch: {
      active: {
        immediate: false,
        handler(active) {
          if (!active) {
            this.fullStop();
          }
        }
      }
    }
  }
  </script>
  
  <style>
  .breadcrumb {
    padding-left:12%;
  }
  ul.breadcrumb{
    margin-top: .5%;
    margin-bottom: .5%;
  }
  
  @media screen and (max-width: 640px) {
    .af {
      flex-direction: column;
    }
  
    .afo {
      width: 100%;
    }
  
    .mt-8 {
      margin-top: 1rem;
    }
  
    .flex-wrap {
      flex-wrap: wrap;
    }
  
    .w-full {
      width: 100%;
    }
  
    .md\:w-1\/3 {
      width: 33.333333%;
    }
  }
  
  button {
    cursor: pointer;
  }
  
  .vue-qr-reader__container {
    width: 100%;
    height: auto;
  }
  </style>