<template>
  

        <div class="bg-green-50 container mx-auto flex flex-col items-center pt-10 pb-10">
          <div>
            <h2 class="title-section text-2xl">
              About Us
          </h2>
          </div>
          <div class="divider mx-auto"></div>
          <br />
          <div>
            <p class="blue-text">
              The Euro Village is a one of its kind, one-stop shop to highlight the best of European Union’s food, products, lifestyle, tourism, culture, and languages and to promote sustainability to a wider Filipino audience in a festive atmosphere.
            </p>
          </div>
          <br />
          <div>
            <p class="blue-text">
              It is also an opportunity to promote people-to-people  links and effective dialogue and interaction with local stakeholders and to find ways to work together to by emphasizing sustainability in savoir-faire.
            </p>
          </div>
          <br />
          <div>
            <p class="blue-text">
              The Euro Village  is open to the public for free from 3 to 4 June 2023 from 4 pm to 12 midnight.
            </p>
          </div>
          <br />
          <!-- <div>
            <p class="blue-text font-bold">
              Objectives</p>
            </div>
            <br />
          <div>
            <ul>
                <li>•	Raise awareness about the EU and its EUMS, their richness and diversity, among Filipino citizens, especially those who have never been exposed to Europe and European products before.</li>
                <li>•	Raise awareness of the EU as a trusted partner of the Philippines in sustainability, green economy, and GI products.</li>
                <li>•	Synergize and create more visibility with EU funded projects in the Philippines that work on environment and sustainability.</li>
                <li>•	Promote the public awareness of EU policy priorities for the Philippines, and develop trust and good understanding with local partners, through various channels/means of engagement, under the Team Europe approach.</li>
              </ul>
          </div> -->
        </div>


</template>

<script>
export default {
  data() {
    return {
      scrollPosition: 0,
    };
  },

  methods: {
    handleScroll(e) {
      this.scrollPosition = e.target.scrollTop;

      if (this.scrollPosition > 100) {
        console.log("UP");
      } else {
        console.log("DOWN");
      }
    },
  },
};
</script>

<style>
h2.title-section {
  color: #04277a;
}

li{
  color: #04277a;
}

h4.title-section {
  color: #04277a;
}

.blue-text{
  color: #04277a;
}

br{
   display:block !important;
}

</style>
