<template>
  <Navbar />
  <!-- <div class="container mt-5">
    <div class="page-banner1">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-6"> -->
          <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ul>
          </nav>

    <div class="py-1 page-section"> 
        <div class="container">
            <button class="block md:hidden" @click="showSidebar = !showSidebar">
                    <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0zm0 6h20v2H0zm0 6h20v2H0z"/></svg>
                  </button>
              <div class="af flex h-screen"  v-if="isAdmin == true">
                  <div class="bg-gray-200 w-64 h-60 afo flex-shrink-0" :class="{ 'hidden': !showSidebar }">
                    <ul class="mt-8">
                      <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer">
                        QR Scanner
                      </li>
                      <li class="px-6 py-2 text-gray-700 hover:bg-gray-400 cursor-pointer" @click="dashboard">
                        Dashboard
                      </li>
                      <li class="px-6 py-2 text-red-700 hover:bg-gray-400 cursor-pointer" @click="signout">
                        Logout
                      </li>
                    </ul>
                  </div>

                    <div class="bg-green-50 flex-1 p-10">
                        <div class="af flex justify-between items-center mb-6">
                          <h1 class="text-2xl font-bold">Hi, Exhibitor {{ nameDisplay }}!</h1>
                        </div>
                            <p>Kindly Scan The QR</p>
                            <QRScanner />
                    </div>
            </div>
        </div>
    </div>
    
   
    <Footer />
</template>
<script>
import Navbar from '../components/Navbar'
import QRScanner from '../components/QRScanner.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import jsQR from "jsqr";
import Footer from "../components/Footer"

import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification
} from 'firebase/auth'

export default {
  data() {
    return {
      userEmail: null,
      nameDisplay: null,
      isAdmin: false,
      error: '',
      inputValue: '',
      showSidebar: true,
      active: false,
      stampCount: 0
    };
  },
  components: {
    Navbar,
    Footer,
    QRScanner
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        console.log("User is signed in.");
        this.initPage(user);
      } else {
        // User is signed out.
        console.log("User is signed out.");
        // alert("Please Log in");
        this.$router.replace('/login')
      }
    });
  },
  methods: {
    dashboard () {
          this.$router.replace('/dashboard')
    },
    initPage (user){
      this.userEmail = user.email;
      this.nameDisplay = user.displayName;
      const uid = user.uid;
      const userRef = firebase.firestore().collection("users").doc(uid);

      userRef.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const role = data.role;
          if(role !== "normal"){
            this.isAdmin = true;
          }
        }
      }).catch((error) => {
        console.error("Error getting user data:", error);
      });
    },
    signout () {
      firebase
        .auth()
        .signOut()
        .then(user => {
          // alert('Logout Successfully')
          this.$router.replace('/')
        })
        .catch(error => {
          alert('Error : ' + err.message)
        })
    }
  }
}
</script>

<style>
.breadcrumb {
  padding-left:12%;
}
ul.breadcrumb{
  margin-top: .5%;
  margin-bottom: .5%;
}

@media screen and (max-width: 640px) {
  .af {
    flex-direction: column;
  }

  .afo {
    width: 100%;
  }

  .mt-8 {
    margin-top: 1rem;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .w-full {
    width: 100%;
  }

  .md\:w-1\/3 {
    width: 33.333333%;
  }
}

button {
  cursor: pointer;
}

.vue-qr-reader__container {
  width: 100%;
  height: auto;
}
</style>

