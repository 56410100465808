<template>
  <Navbar />
  <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Signup</li>
            </ul>
          </nav>
  <div class="container mt-5">
    <div class="bg-green-50 page-banner2 my-10">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-6">
          
          <h1 class="text-center">Registration</h1>

          <div class="input-group flex-nowrap mt-3">
            <span class="input-group-text" id="addon-wrapping">Name</span>
            <input
              v-model="displayName"
              type="displayName"
              class="form-control"
              placeholder="Display Name"
              aria-label="Display Name"
              aria-describedby="addon-wrapping"
            />
          </div>

          <div class="input-group flex-nowrap mt-3">
            <span class="input-group-text" id="addon-wrapping">Email</span>
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email"
              aria-label="Email"
              aria-describedby="addon-wrapping"
            />
          </div>

          <div class="input-group flex-nowrap mt-3">
            <span class="input-group-text" id="addon-wrapping">PW</span>
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
              aria-label="Password"
              aria-describedby="addon-wrapping"
            />
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-primary btn-lg align-items-center mt-5 px-5 fw-md"
            >
              Sign Up
            </button>
          </div>

          <div class="text-center mt-4">
            <p class="px-4">
              Already have an account?
              <router-link class="underline" to="/login">Login Here</router-link>
            </p>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
  </div>


  
  <Footer />
</template>

<script>
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification
} from 'firebase/auth'

export default {
  name: 'signUp',
  data () {
    return {
      email: '',
      password: '',
      username: '',
      displayName: '',
      alertBox : false,
    }
  },

  components: {
    Navbar,
    Footer
  },

  methods: {
    signUp () {
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        // User created successfully
        const user = userCredential.user;
        // Update the user's profile with display name
        return user.updateProfile({
          displayName: this.displayName,
        });
      })
      .then(() => {
        // User profile updated successfully
        console.log('User Successfully created!');
        this.$router.replace('dashboard')
      })
      .then(() => {
        const user = firebase.auth().currentUser;
        const uid = user.uid;
        const userRef = firebase.firestore().collection("users").doc(uid);

        userRef.set({
          role: "normal",
          belgium : "",
          czech : "",
          denmark : "",
          germany : "",
          spain : "",
          france : "",
          italy : "",
          hungary : "",
          netherlands : "",
          austria : "",
          poland : "",
          romania : "",
          finland : "",
          ukraine : "",
          others1 : "",
          others2 : "",
          others3 : "",
          others4 : "",
          others5 : "",
          others6 : "",
          others7 : "",
          others8 : "",
          others9 : "",
          others10 : "",
          count : ""
        }).then(() => {
          console.log("Normal User Registered");
        }).catch((error) => {
          console.error("Error Normal User Registered", error);
        });
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
    }
  }
}
</script>

<style>
.input-group-text {
  width: 25%;
}

.btn-primary {
  background-color: rgb(74, 78, 78);
}
</style>
