<template>
    <Navbar />
      <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Exhibitors</li>
            </ul>
          </nav>
 

    <Exhibitors />
  
    <Footer :blog="blog" />
  </template>
  
  <script>
  import Navbar from "../components/Navbar";
  import Exhibitors from "../components/Exhibitors";
  import Footer from "../components/Footer";
  
  export default {
    name: "Exhibitors Page",
    data() {
      return {};
    },
  
    props: {
      blog: Array,
    },
  
    components: {
      Navbar,
      Exhibitors,
      Services,
      Footer,
    },
  };
  </script>
  
  <style>
  .breadcrumb {
    padding-left:12%;
  }
  ul.breadcrumb{
    margin-top: .5%;
    margin-bottom: .5%;
  }
  </style>
  