<template>

<div class="container mx-auto flex flex-col items-center pt-10">
    <div><img src="../../assets/EUMemberStates/pl.png" class="flag border border-gray-400" alt="Poland"/></div>
      <div>
        <h2 class="title-section text-2xl">
        Poland
      </h2>
      </div>
      <div class="divider mx-auto"></div>
</div>
    

    <div class="container mx-auto flex flex-col justify-start pb-10">
      <div>
        <h2 class="text-blue text-xl">
          1 - OLEO FATS</h2>
        <p class="text-blue">
          Farmhaven Mozzarella / Pizza Topping</p>
        <p class="text-blue">
          Our mozzarella is the reflection of the highest Polish dairy standard with its impressive melting and stretching qualities as well as milky and buttery texture. Perfect for toasted cheese baguettes, pizza, or lasagna. Available in Block format and shredded format.</p>
          <img src="../../assets/EUMemberStates/poland/mozzerella.jpg" class="center" alt="Poland"/>
        <p class="text-blue">
            Mlekovita UHT Milk</p>
        <p class="text-blue">
            100% cows milk from the the green pastures of Poland. Our Mlekovita UHT Milk is available in two formats: Full Cream (3.5% fat content) and Non-fat (0.5% fat content). Enjoy fresh-tasting, high-quality dairy that will make your desserts and drinks more creamy and tasty!</P>
        <img src="../../assets/EUMemberStates/poland/milk.jpg" alt="Poland"/>
      </div>
      <br />
      <br />
      <div>
        <h2 class="text-blue text-xl">
          2 - PALAWAN SMOKERS</h2>
        <p class="text-blue">
          Palawan Smokers will be offering free tasting for the following items:</p>
        <p class="text-blue">
          &nbsp; •	Smoked Polish Sausage</p>
          <p class="text-blue">
            &nbsp; •	Spicy Sausage </p>
          <p class="text-blue">
            &nbsp; •	Traditional White Sausage </p>
          <p class="text-blue">
            &nbsp; •	Smoked Forest Ham </p>
          <p class="text-blue">
            &nbsp; •	Smoked Bacon 
          </p>
          <p class="text-blue">
            Our sausages are made with quality local pork, coarse grind with a selection of spices.  Ham and Bacon are smoked and prepared in a traditional Polish way. Marinade for many days and smoked for at least 5 hours. 
          </p>
          <p class="text-blue">
            All our product reflects Poland’s rich culinary traditions and are proudly crafted in the Philippines.</p>
            <div class="flex">
              <img src="../../assets/EUMemberStates/poland/smoke1.jpg" class="center" alt="Poland"/>&nbsp;<img src="../../assets/EUMemberStates/poland/smoke2.jpg" class="center" alt="Poland"/>&nbsp;<img src="../../assets/EUMemberStates/poland/smoke3.jpg" class="center" alt="Poland"/>
            </div>
      </div>
      <br />
      <br />
      <div>
        <h2 class="text-blue text-xl">
          3 - REACHMORE</h2>
          <img src="../../assets/EUMemberStates/poland/apple.jpg" class="center" alt="reachmore"/>
        <p class="text-blue">
          Cultivation of apple trees, love for the land, and respect for the surrounding nature are passed down from generation to generation in our family.</p>
        <p class="text-blue">
          Royal Apple dream was to share this passion with others - this is how the Activ company was founded in 1996. We operate in the very heart of orchards and horticulture in Poland. This is where we find raw materials of the highest quality to produce juices and ciders. Royal Apple Polish fruit growers in implementing innovative technologies that reduce the use of chemicals during production and the development of environmentally friendly fruit crops.</p>
          <p class="text-blue">
            Our juices are 100% natural. We don’t add any:</p>
          <p class="text-blue">
            &nbsp; - sugar</p>
          <p class="text-blue">
            &nbsp; - water</p>
          <p class="text-blue">
            &nbsp; - preservatives 
          </p>
          <img src="../../assets/EUMemberStates/poland/flavors.jpg" class="center" alt="flavors"/>
      </div>
    </div>
</template>

<script>
export default {
data() {
return {
  scrollPosition: 0,
};
},

methods: {
handleScroll(e) {
  this.scrollPosition = e.target.scrollTop;

  if (this.scrollPosition > 100) {
    console.log("UP");
  } else {
    console.log("DOWN");
  }
},
},
};
</script>

<style>
h2.title-section {
color: #04277a;
}

h2.blue-text{
color: #04277a;
}

.blue-text{
color: #04277a;
}

br{
display:block !important;
}
</style>
