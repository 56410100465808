<template>
  <header>
    <div class="relative hidden md:block">
  <img class="w-full h-auto object-scale-down object-scale-down-50" src="../assets/HomepageBanner2.jpg" alt="Banner Image">
  <div class="absolute inset-0 flex items-center justify-center">
  </div>
</div>
  </header>
</template>

<script>
import { directive as motion } from "@vueuse/motion";

export default {};
</script>

<style>
p{
  color: #04277a;
}





</style>
