<template>
 

  <div class="md:hidden fixed bottom-0 left-0 z-20 w-full rounded-t-xl drop-shadow-xl px-1 bg-gray-50 border-t border-gray-200 shadow ms:flex ms:items-center ms:justify-between ms:p-6 dark:bg-gray-800 dark:border-gray-600">
    <div>
      <div
        class="flex flex-wrap items-center justify-evenly p-1 space-x-5 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
        <router-link
          @click="isActive"
          to="/"
          class="flex flex-col items-center justify-center">
          <div
            class="flex items-center hover:no-underline mt-1 text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700"
            aria-current="page">
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 hover:-mt-1 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"></path>
            </svg>
          </div>
          <div
            class="flex items-center justify-center mt-1 text-green-900 hover:no-underline font-medium rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <p class="">Home</p>
          </div>
        </router-link>

        <router-link
          to="/about"
          class="flex flex-col items-center justify-center">
          <div
            class="flex items-center hover:no-underline mt-1 text-gray-900 rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
            </svg>
          </div>
          <div
            class="flex items-center justify-center mt-1 text-green-900 font-medium rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <p class="whitespace-nowrap">About Us</p>
          </div>
        </router-link>

        <router-link
          to="/exhibitors"
          class="flex flex-col items-center justify-center">
          <div
            class="flex items-center hover:no-underline mt-1 text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700"
            aria-current="page">
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 5.25A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25v9.5A2.25 2.25 0 0116.75 17H3.25A2.25 2.25 0 011 14.75v-9.5zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-2.69l-2.22-2.219a.75.75 0 00-1.06 0l-1.91 1.909.47.47a.75.75 0 11-1.06 1.06L6.53 8.091a.75.75 0 00-1.06 0l-2.97 2.97zM12 7a1 1 0 11-2 0 1 1 0 012 0z"></path>
            </svg>
          </div>
          <div
            class="flex items-center justify-center mt-1 text-green-900 font-medium rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <p class="whitespace-nowrap">Exhibitors</p>
          </div>
        </router-link>

        <router-link v-if="this.isloggedIn == false"
          to="/login"
          class="flex flex-col items-center justify-center">
          <div
            class="flex items-center hover:no-underline mt-1 text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700"
            aria-current="page">
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
              </svg>
          </div>
          <div
            class="flex items-center justify-center mt-1 text-green-900 font-medium rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <p class="whitespace-nowrap">Login</p>
          </div>
        </router-link>

        <router-link v-if="this.isloggedIn == true"
          to="/dashboard"
          class="flex flex-col items-center justify-center">
          <div
            class="flex items-center hover:no-underline mt-1 text-gray-900 rounded-lg dark:text-white hover:bg-blue-100 dark:hover:bg-gray-700"
            aria-current="page">
            <svg
              aria-hidden="true"
              class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
              fill="currentColor"
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
              </svg>
          </div>
          <div
            class="flex items-center justify-center mt-1 text-green-900 font-medium rounded-lg dark:text-white dark:hover:bg-gray-700"
            aria-current="page">
            <p class="whitespace-nowrap">Dashboard</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
export default {
  data() {
    return {
      isloggedIn : false,
    };
  },
  props: {
    blog: Array,
  },
  created() {
    // Get the current user's email
    // const user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.isloggedIn = true;
        // this.initPage(user);
      } else {
        // User is signed out.
        console.log("2");
        this.isloggedIn = false;
        // alert("Please Log in");
        // this.$router.replace('/login')
      }
    });
  },
  methods : {
    signout () {
      firebase
        .auth()
        .signOut()
        .then(user => {
          // alert('Logout Successfully')
          this.$router.replace('/home')
        })
        .catch(error => {
          alert('Error : ' + err.message)
        })
    }
  }
};
</script>

<style>

span {
  color: #04277a;
}
</style>
