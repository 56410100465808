<template>
  <div class="hidden md:block">
    <div class="hidden md:block">
      <nav
        class="container mx-auto ml-10 md:flex md:justify-between md:items-center pb-3">
          <span class="self-center hover:text-green-600 text-xl font-semibold whitespace-nowrap dark:text-white">
            <img src="../assets/FINALEuroVillageLogo.png" alt="Logo" class="logo-euro hidden md:block align-text-top me-2">
          </span>
        <div class="flex items-center justify-between ">
          <!-- Mobile menu button -->
          <div v-if="this.isloggedIn == true" @click="showMenu = !showMenu" class="flex md:hidden">
            <button
              type="button"
              class="
                text-gray-800
                hover:text-gray-400
                focus:outline-none focus:text-gray-400
              "
            >
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>


        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->

        <ul
          :class="showMenu ? 'flex' : 'hidden'"
          class="
            flex-col
            space-y-4
            md:flex
            md:space-y-0
            md:flex-row
            md:items-center
            md:space-x-10
            md:mt-0
          ">
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400 hdd">
            <router-link
              to="/"
              class="flex items-center hover:no-underlinep-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Home</span>
            </router-link>
          </li>
          <!-- <li class="text-sm font-bold text-gray-800 hover:text-blue-400 hdd">
            <router-link
              to="/"
              class="flex items-center hover:no-underline bg-white-50 p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-white-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Home</span>
            </router-link>
          </li> -->
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400 hdd">
            <router-link
              to="/about"
              class="flex items-center hover:no-underlinep-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">About Us</span>
            </router-link>
          </li>
          <li class="text-sm font-bold text-gray-800 hover:text-blue-400 hdd">
            <router-link
              to="/exhibitors"
              class="flex items-center p-2 ml-2 hover:no-underline text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                  d="M1 5.25A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25v9.5A2.25 2.25 0 0116.75 17H3.25A2.25 2.25 0 011 14.75v-9.5zm1.5 5.81v3.69c0 .414.336.75.75.75h13.5a.75.75 0 00.75-.75v-2.69l-2.22-2.219a.75.75 0 00-1.06 0l-1.91 1.909.47.47a.75.75 0 11-1.06 1.06L6.53 8.091a.75.75 0 00-1.06 0l-2.97 2.97zM12 7a1 1 0 11-2 0 1 1 0 012 0z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Exhibitors</span>
            </router-link>
          </li>
          <li v-if="this.isloggedIn == false" class="text-sm font-bold text-gray-800 hover:text-blue-400 hdd">
            <router-link
              to="/login"
              class="flex items-center hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Login/Signup</span>
            </router-link>
          </li>
          <li v-if="this.isloggedIn == true" class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <router-link
              to="/dashboard"
              class="flex items-center hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"><path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Dashboard</span>
            </router-link>
          </li>
          <li v-if="this.isloggedIn == true" class="text-sm font-bold text-gray-800 hover:text-blue-400">
            <!-- <router-link
              to="/login"
              class="flex items-center hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700"
              aria-current="page">
              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
              </svg>
              <span class="flex-1 ml-2 whitespace-nowrap">Logout</span>
            </router-link> -->
            <a href="#" @click="signout()" class="flex items-center hover:no-underline p-2 ml-2 text-gray-900 rounded-lg dark:text-white hover:bg-green-100 dark:hover:bg-gray-700">              <svg
                aria-hidden="true"
                class="flex-shrink-0 w-6 h-6 text-blue-700 transition duration-75 dark:text-gray-400 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 7.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"></path>
                </svg>
              <span class="flex-1 ml-2 whitespace-nowrap red">Logout</span>
            </a>
            <!-- <button @click="signout" class="btn btn-primary flex-1 ml-2 whitespace-nowrap height:10px"> -->
              
            <!-- </button> -->
          </li>
        </ul>
      </nav>
    </div>
  </div>

  
  <div class="head-hidden md:hidden h-100"><br /></div>
</template>
<script>

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
export default {
  data() {
    return {
      showMenu: false,
      isloggedIn : false,
    };
  },
  created() {
    // Get the current user's email
    // const user = firebase.auth().currentUser;
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.isloggedIn = true;
        // this.initPage(user);
      } else {
        // User is signed out.
        console.log("2");
        this.isloggedIn = false;
        // alert("Please Log in");
        // this.$router.replace('/login')
      }
    });
  },
  methods : {
    signout () {
      firebase
        .auth()
        .signOut()
        .then(user => {
          // alert('Logout Successfully')
          this.$router.replace('/')
        })
        .catch(error => {
          alert('Error : ' + err.message)
        })
    }
  }
};
</script>

<style>
@media (max-width: 767px) {
  li.hdd {
    display: none;
  }
}

@media (max-width: 767px) {
  /* nav.container {
    background-color: #cee1f3;
  } */

  .head-hidden {
  background-image: url('../assets/HomepageBanner2.jpg');
  background-size: cover; /* resize image to cover entire div */
  background-position: center; /* center the image */
  background-repeat: no-repeat; 
  width: 100%;
  height: 290px!important; /* adjust the height to your needs */
  max-width:100%;
}
}
span {
  color: #04277a;
}

span.red {
  color: red;
}

img.logo-euro{
width: 100%;
height: 120px;
}

nav.container{
  padding-top: 20px;
}
</style>
