<template>
  <Navbar />
          <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">About Us</li>
            </ul>
          </nav>

  <Aboutus />
<br />
<br />
<br />
<br />
  <Footer :blog="blog" />
</template>

<script>
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import Footer from "../components/Footer";

export default {
  name: "About Page",
  data() {
    return {};
  },

  props: {
    blog: Array,
  },

  components: {
    Navbar,
    Aboutus,
    Services,
    Footer,
  },
};
</script>

<style>
.breadcrumb {
  padding-left:12%;
}
ul.breadcrumb{
  margin-top: .5%;
  margin-bottom: .5%;
}
</style>
