import { createWebHistory, createRouter } from 'vue-router';

import Home from '../components/Home.vue';
import About from '../discover/AboutPage.vue';
import Contact from '../components/Contact.vue';
import Exhibitors from "../discover/ExhibitorsPage.vue";


import PageNotFound from "../components/PageNotFound.vue";


import Login from '../firebase/Login.vue';
import Reset from '../firebase/Reset.vue';
import Signup from '../firebase/Signup.vue';
import Dashboard from '../firebase/Dashboard.vue';
import Stamps from '../firebase/Stamps.vue';
import Scanner from '../firebase/Scanner.vue';
import Upload from "../firebase/Upload.vue";



const routes = [{
        path: "/",
        component: Home,
    },
    {
        path: "/about",
        component: About,
    },
    {
        path: "/contact",
        component: Contact,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/signup",
        component: Signup,
    },
    {
        path: "/dashboard/:value",
        component: Dashboard,
    },
    {
        path: "/dashboard",
        component: Dashboard,
    },
    {
        path: "/stamps",
        component: Stamps,
    },
    {
        path: "/scanner",
        component: Scanner,
    },
    {
        path: "/upload",
        component: Upload,
    },
    {
        path: "/exhibitors",
        component: Exhibitors,
    },
    {
        path: "/reset",
        component: Reset,
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound,
    },
];





const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    linkActiveClass: 'vue-school-active-link',

});

export default router;