<template>
  <Navbar />
  
  <nav aria-label="Breadcrumb">
            <ul class="breadcrumb justify-content-right py-0 bg-transparent">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Signup</li>
            </ul>
          </nav>
  <div class="container mt-5">
    <div class="bg-green-50  page-banner2 my-10">
      <div class="row justify-content-center align-items-center h-100">
        <div class="col-md-6">
          <br />
          <h1 class="text-center">Kindly input your email address</h1>
          <div class="input-group flex-nowrap mt-5">
            <span class="input-group-text" id="addon-wrapping">Email :</span>
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Email"
              aria-label="Username"
              aria-describedby="addon-wrapping" 
            />
          </div>
          <div class="text-center">
            <button
              @click="resetPass"
              type="button"
              class="btn btn-primary btn-lg align-items-center mt-5 px-5 fw-md" >
              Send Password Reset Link
            </button>
          </div>
          <div v-if="message" class="alert alert-success">{{ message }}</div>
          <div class="text-center mt-4">
            <p class="px-4 t text-align: center">
              <router-link class="underline" to="/login">Back to login</router-link>
            </p>
          </div>
          &nbsp;
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import firebase from 'firebase/compat/app'
import Footer from '../components/Footer'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import Navbar from '../components/Navbar'

export default {
  name: 'Reset',
  data () {
    return {
      email: '',
      message: ''
    }
  },

  components: {
    Navbar,
    Footer
  },

  methods: {
    async resetPassword() {
      try {
        await projectAuth.sendPasswordResetEmail(this.email);
        this.message = 'An email has been sent to reset your password.';
        this.email = '';
      } catch (error) {
        console.log(error);
        this.message = 'Oops! Something went wrong. Please try again.';
      }
    }
  }
}
</script>

<style>
br{
   display:block !important;
}
.btn-primary {
  background-color: rgb(74, 78, 78)!important;
}
</style>
